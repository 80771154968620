import React from "react"
import Layout from "../../components/Layout"
import loadable from "@loadable/component"

// Lazy load the Driverbee component
const Driverbee = loadable(() => import("../../components/Driverbee"), {
  fallback: <div className="loading-fallback">Loading Driverbee...</div>, // Fallback while loading
})

export default function TrackHero() {
  return (
    <Layout>
      <Driverbee />
    </Layout>
  )
}
